import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import { get } from "@rails/request.js";
import Rollbar from "rollbar";

export default class extends Controller {
  static values = {
    showSearch: { type: Boolean, default: true },
    placeholder: { type: String, default: "Select an option" },
    maxSelected: { type: Number, default: 100 },
    allowDeselect: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: false },
    searchUrl: { type: String, default: "" },
  };

  connect() {
    // SlimSelect does NOT currently support required attribute,
    // Including the required attribute will cause a console error rather than show a validation message
    // So we need to remove it and rely on server-side validation
    this.element.removeAttribute("required");

    // Remove the form-control class from the select element since it tries to fix height
    // And we allow SlimSelect to manage the height of the dropdown
    this.element.classList.remove("form-control");

    // if allowDeselect is true, then SlimSelect requires that we have an empty option with data-placeholder="true"
    // Lets manage that here rather than hope it is setup correctly in the view
    // https://slimselectjs.com/settings#allowDeselect
    if (this.allowDeselectValue) {
      // Remove existing empty option if it exists
      const existingEmptyOption =
        this.element.querySelector('option[value=""]');
      if (existingEmptyOption) {
        existingEmptyOption.remove();
      }

      // Inject a new blank option at the top of the select with the attributes required by SlimSelect
      this.element.insertAdjacentHTML(
        "afterbegin",
        `<option value="" data-placeholder="true" disabled>${this.placeholderValue}</option>`
      );
    }

    this.slimSelect = new SlimSelect({
      select: this.element,
      events: this.#events,
      settings: {
        allowDeselect: true,
        showSearch: this.showSearchValue,
        maxSelected: this.maxSelectedValue,
        closeOnSelect: this.closeOnSelectValue,
      },
    });

    // Close the dropdown when the user presses the tab or escape key
    this.handleKeyboard = this.handleKeyboard.bind(this);
    document.addEventListener("keydown", this.handleKeyboard);
  }

  handleKeyboard(event) {
    if (event.key === "Tab" || event.key === "Escape") {
      this.slimSelect.close();
    }
  }

  search(query) {
    this.slimSelect.search(query);
  }

  async #performSearch(search) {
    if (search.length < 2) {
      return new Promise((_, reject) => {
        return reject("Search must be at least 2 characters");
      });
    }

    try {
      const response = await get(`${this.searchUrlValue}`, {
        responseKind: "json",
        query: { query: search },
      });
      if (response.ok) {
        const options = await response.json;
        return new Promise((resolve) => resolve(options));
      } else {
        const error = await response.json;
        Rollbar.error("Failed to search for options", error);
        return new Promise((_, reject) => reject(error.message));
      }
    } catch (error) {
      Rollbar.error("Failed to search for options", error);
      return new Promise((_, reject) => reject(error.message));
    }
  }

  get #events() {
    const events = {};
    if (this.searchUrlValue) {
      events["search"] = this.#performSearch.bind(this);
    }
    return events;
  }
}
