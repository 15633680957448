import { Controller } from "@hotwired/stimulus";

// IMPORTANT: This controller only supports checkboxes at present. Do not use
// with other field types until you implement support for them.
export default class extends Controller {
  connect() {
    this.submitButton.disabled = true;

    this.requiredFields.forEach((field) => {
      field.addEventListener("input", this.#checkFields.bind(this));
    });
  }

  #checkFields() {
    this.submitButton.disabled = !this.allFieldsComplete;
  }

  get allFieldsComplete() {
    // Implement other field types here.
    return this.requiredFields.every((field) => field.checked);
  }

  get requiredFields() {
    return Array.from(this.element.querySelectorAll("[required]"));
  }

  get submitButton() {
    return this.element.querySelector("input[type=submit]");
  }
}
